

/*.not-fixed-bottom {*/
    /*border-top: black;*/
    /*border-width: thin;*/
    /*padding-top: 10px;*/

    /*background: #E9ECEE;*/




/*}*/


#footer {
    /*position: absolute;*/
    left: 0;
    bottom: 0;
    /*color: white;*/
    height: 100px;
    width: 100%;
    /*background-color: red;*/
    text-align: center;
    margin-top: 50px;
    /*padding-top: -200px;*/
    /*background-color: #E9ECEE;*/
}


/*.wrapper {*/
    /*min-height: 100%;*/
    /*position: relative;*/
/*}*/

/*.content {*/
    /*!* padding the footer adds 40 to footer height *!*/
    /*padding-bottom: 140px;*/
/*}*/

/*#footer {*/
    /*position: absolute;*/
    /*bottom: 0;*/
    /*background: #444444;*/
    /*height: 100px;*/
    /*font-family: 'Open Sans', sans-serif;*/
    /*color: #FFFFFF;*/
    /*padding: 20px;*/
/*}*/