/*.App {*/
  /*text-align: center;*/
/*}*/

/*.App-logo {*/
  /*animation: App-logo-spin infinite 20s linear;*/
  /*height: 40vmin;*/
  /*pointer-events: none;*/
/*}*/

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/

/*.App-link {*/
  /*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/


#nav{
    padding-top: 30px;
}


nav-item{
    margin: 12px;
}


#headerpic{
    width: 120%;
    height: auto;
}

.link{
    /*font-family: Charcoal;*/
    font-size: 24px;
    color: darkgrey !important;
    text-decoration: none!important;
    font-weight: bold;
    font-family: sans-serif;
    /*margin: -100px!important;*/
}






.link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}


.link {
  transition: all 0.5s;
}


.link:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}


.link:hover {
    color: white!important;
    background-color: black!important;
    z-index: -1;
}

.link:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}


/*@-webkit-keyframes fill {*/
  /*0% {*/
    /*width: 0%;*/
    /*height: 1px;*/
  /*}*/
  /*50% {*/
    /*width: 100%;*/
    /*height: 1px;*/
  /*}*/
  /*100% {*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*background: #333;*/
  /*}*/
/*}*/

/*.full-width-div {*/
/*position: absolute;*/
/*width: 100%;*/
/*left: 0;*/
/*}*/



