#scroll{
    overflow: auto;
    /*max-width: 800px;*/
    height: 500px;
    /*height: 500px;*/
    width: 100%;

      display: flex;
  align-items: center;
  /*justify-content: center;*/


}


#scrollImg{
    /*overflow: auto;*/
    /*max-width: 800px;*/
    height: auto;
    /*height: 500px;*/
    width: 2500px;
    /*display: inline-block;*/
    vertical-align: middle;

}

/*.Centered*/
/*{*/
    /*display: inline-block;*/
    /*vertical-align: middle;*/
/*}*/