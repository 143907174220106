.text{
    font-size: 18px;
    display: inline;
    text-align: left;
    font-family: Apple;
}


/*parent div of text*/
.col-4{
    text-align: left;
}




.left{
    font-size: 100px;
    font-family: Helvetica;
    font-weight: bold;
    transform: translate(-100px, 400px) rotate(-90deg) ;
    color: black;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    margin: -40px;
}






.rectangle{
    fill: black;
    /*fill: #24FFB7;*/
}


.icon{
    height: 50px;
    width: auto;
    /*text-align: center;*/
    /*display: block;*/
}


.col {
    margin-top: 40px;
    padding-top: 50px;
    text-align: center;
}


.sm_text{
    display: block;
}


.col:hover{
    background-color: lightgrey;
}


.text-new-one{
    margin-top: 10px;

    margin-bottom: -20px;
}