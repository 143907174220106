iframe{
    margin-top: -50px;
    margin-bottom: -70px;
}


.link_to_proj{
    margin-top: 20px;
    margin-bottom: 50px;
}


#demo_scene{
    margin-bottom: -70px;
    margin-top: -20px;

}