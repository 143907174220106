.hidden {
    display: none;
}






a[class="frontpage"]{
    font-family: Arial, Verdana, sans-serif;
    font-size:18px;
    line-height: 150%;
    color:#999999;
}


.img-list {
    width: 88%;
    text-align: left;
    /*margin-left: -10px;*/
    padding:0;
}

.img-list li {
    display: inline-block;
    width: 300px;
    height:220px;
    /*margin: 10px;*/
}


.img-list figure {
    height: 220px;
    overflow: hidden;
    position: relative;
    width: 290px;
    margin: 0;

}

.img-list figcaption {
    background: rgba(0,0,0,0.5);
    color: white;
    display: table;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    -webkit-transition-delay: 0ms;
    -moz-transition-delay: 0ms;
    transition-delay: 0ms;
    z-index: 100;
}


.img-list li:hover figcaption {
    opacity: 0.8;
}

.img-list img {
    display: block;
    height:230px;
    width:auto;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    /* max-width: 100%; */
}

.img-list li:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
}

.img-list figcaption p {
    font-family: Verdana,Geneva,sans-serif;
    font-size: 18px;
    display: table-cell;
    position: relative;
    width: 290px;
    vertical-align: middle;
    text-align: center;
    text-transform:uppercase;
    font-weight: bold;
    word-break:break-all;
}

img-list li:hover figcaption p {
    -moz-transform: translateY(40px);
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
}

a[class="frontpage"]:hover{
    -webkit-filter:brightness(0%);
}


#footer{
    font-size:12px;
    text-align:right;
    text-decoration:none;
    color:#999999;
    margin-top: 60px;
    bottom: 0;
    /* position:absolute; */
    font-family:Source Sans pro;
}
