

a[class="frontpage"]{
    font-family: Arial, Verdana, sans-serif;
    font-size:18px;
    line-height: 150%;
    text-decoration: none;
    color:#999999;
}


.projectName{
    font-size:40px;
    font-family: Arial,sans-serif;
    color: #707070;
    font-weight: bold;
    /*margin-bottom:-20px;*/
}



.title{
    color:#666666;
    font-size:30px;
    font-family: Georgia, sans-serif;
    text-align: left;
    font-weight: bold;
    color: black;
    /*margin-top: 80px;*/
    /*margin-bottom:-5px;*/
}


.title_secondary{
    color:#666666;
    font-size:24px;
    font-family: Georgia, sans-serif;
    text-align: left;
    font-weight: bold;
    margin-top: 80px;
    /*padding-top: 80px;*/
    /*margin-bottom:10px;*/
}


.generaltext{
    font-family:Source Sans pro;
    line-height:23px;
    font-size:16px;
    text-align:left;
    word-spacing: 0px;
}

/* 总体介绍 */


.projectinfo{
    font-family: Arial, Verdana, sans-serif;
    font-size:14px;
    text-align: left;
    color:#666666;
    line-height:130%;
}

.text-content{
    display: block;
    text-align:left;
    margin:5px;
    font-family: Arial, Verdana, sans-serif;
    font-size:14px;
    color:black;
}/* 每张图的小标题span */

p{
    font-family:Source Sans pro;
    line-height:23px;
    font-size:16px;
    text-align:left;
    word-spacing: 0px;
}/* 中间介绍部分文字 */





.wide {
    list-style-type: none;
    /* position: absolute; */
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}


.narrow {
    list-style-type: none;
    /* text-align: center; */
    margin:0 auto;
    /* position: absolute; */
    width: 80%;
    height: 100%;
    /* padding: 0;
    margin: 0; */
}


img{
    /*padding-top:10px;*/
    margin-bottom: 15px;
    /* width:1200px; */
    width:100%;

    height:auto;
}

ul.img_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align:left;
}

ul.img_list li {
    height:auto;
    margin:0;
    padding-top: 40px;
    /*text-align: left;*/
    /*font-size: 100px;*/
}


.points_list li{
    text-align: left;
    font-size: 20px;
}









/*.left{*/
    /*width: 1300px;*/
    /*display: inline;*/
/*}*/

/*.small{*/
    /*width: 810px;*/
    /*display: inline;*/
/*}*/

a{
    text-decoration: none;
}/* 去掉span的下划线 */

a[class="frontpage"]:hover{
    -webkit-filter:brightness(0%);
}

#footer p{
    font-size:12px;
    text-align:right;
    text-decoration:none;
    color:#999999;
    margin-top: 60px;
    font-family:Source Sans pro;
}

#gotop{
    display:block;
    width:40px;
    position:fixed;
    bottom:30px;
    right:40px;
}

#gotop:hover{
    -webkit-filter:opacity(300%);
    -webkit-filter:drop-shadow(2px 2px 2px grey);
    cursor: pointer;
}
