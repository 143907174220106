/*img[id=headerpic]{*/
    /*width:1300px;*/
    /*margin:0 auto;*/
/*}*/

/*#header{*/
    /*text-align: right;*/
/*}*/


/*.frontPage{*/
    /*font-family: Arial, Verdana, sans-serif;*/
    /*font-size:18px;*/
    /*line-height: 150%;*/
    /*color:#999999;*/
/*}*/


/*.dropdown-item-button:not(.open) ul.dropdown-menu{*/
    /*display:block !important;*/
    /*opacity:0;*/
    /*pointer-events:none;*/
/*}*/


.flex-column {
    text-align: right;
    /*display: none;*/
}


.link{
    color: gray;
    /*padding: 100px;*/

}

.link:hover{
    color: black;
    /*padding: 100px;*/

}

.full-width-div {
    position: absolute;
    width: 100%;
    left: 0;
}